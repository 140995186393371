import {API} from '@stoplight/elements';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@stoplight/elements/styles.min.css';
import './App.css';


export function getMetaEnv(name, defaultValue, deep) {
    if (window.localStorage && deep) {
        const value = window.localStorage.getItem(name);
        if (value) {
            return value;
        }
    }
    const meta = document.querySelector(`meta[name=${name}]`);
    if (meta) {
        return meta.content ? meta.content : defaultValue;
    }
    return defaultValue;
}

function App() {
    return (
        <div className="App">
            <API
                apiDescriptionUrl={getMetaEnv("DOCUMENT_YAML_URL")}
                hideSchemas="true"
                staticRouterPath="/micro-frontend/document/openapi"
                router="hash"
            />
        </div>
    );
}

export default App;
